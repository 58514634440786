import { Account } from 'common/models';
import { accountRepository } from 'common/repositories';
import { createFetchStore } from 'common/utils';

export const {
  useFetchStore: useAccountsStore,
  useFetchData: useFetchAccounts,
} = createFetchStore<Account[] | null>(
  async () => (await accountRepository.getAccounts()).data
);

import { Input } from '@headlessui/react';
import { clsx } from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';

import { InputProps } from './types';

export type DefaultInputProps = Pick<InputProps, 'className'> &
  InputHTMLAttributes<HTMLInputElement> & {
    renderIcon?: () => React.ReactNode;
  };

// eslint-disable-next-line react/display-name
const DefaultInput = forwardRef<HTMLInputElement, DefaultInputProps>(
  ({ className, renderIcon, ...rest }, ref) => {
    return (
      <div className="relative">
        <Input
          ref={ref}
          {...rest}
          className={clsx(
            'w-full rounded-md border-[1px] border-slate-300 bg-slate-200 px-3 py-4 text-base',
            className
          )}
        />
        <div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
          {renderIcon && renderIcon()}
        </div>
      </div>
    );
  }
);

export default DefaultInput;

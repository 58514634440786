import {
  SendPhoneCodeBody,
  VerifyPhoneCodeBody,
  AccountWithTokens,
} from 'common/models';
import http from 'core/services/api';

class AuthRepository {
  sendPhoneCode = async (body: SendPhoneCodeBody) =>
    http.fetch((axios, signal) =>
      axios.post<object>('/auth/phone/send-code', body, { signal })
    );

  verifyPhoneCode = async (body: VerifyPhoneCodeBody) =>
    http.fetch((axios, signal) =>
      axios.post<AccountWithTokens>('/auth/phone/verify', body, { signal })
    );

  refreshTokens = async () =>
    http.fetch((axios, signal) =>
      axios.post<AccountWithTokens>('/auth/refresh', {}, { signal })
    );
}

export const authRepository = new AuthRepository();

import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useQueryString } from 'common/hooks';
import { useAuthStore } from 'common/stores';
import { Wrapper } from 'core/components/common';
import { OtpCodeInput } from 'core/components/form';
import CircularIndicator from 'core/components/uikit/CircularIndicator';
import { PATHS, PhoneAndEmailQueryParams } from 'core/router';

import useOnboardingStore from '../onboardingStore';

const schema = yup
  .object({
    code: yup
      .string()
      .required(
        t('errors.validation.isRequired', {
          name: t('pages.verify.field'),
        })
      )
      .min(6)
      .max(6),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

type VerifyProps = { type: 'phone' | 'email' };

const Verify: FC<VerifyProps> = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    params: { phone, email },
  } = useQueryString<PhoneAndEmailQueryParams>();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: { code: '' },
  });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(async ({ code }) => {
    const isPhoneVerification = type === 'phone';
    const accountStore = useOnboardingStore.getState();

    if (isPhoneVerification) {
      const authStore = useAuthStore.getState();
      const isAuth = !!authStore.accessToken;
      const body = { phone, code };
      const verifyPhoneAction = isAuth
        ? accountStore.linkAnotherAccount(body)
        : authStore.verifyPhoneCode(body);

      const account = await verifyPhoneAction;

      if (!account) return;

      const addEmailPath = PATHS.getEmail({
        email: email ?? account.email,
        phone,
      });
      const path = (account.emailVerified ?? false) ? PATHS.home : addEmailPath;
      navigate(path);
    } else {
      const success = await accountStore.verifyEmail({ email, code });

      if (success) {
        navigate(PATHS.getDone({ email, phone }));
      }
    }
  });

  const resendCode = async () => {
    const action =
      type === 'phone'
        ? useAuthStore.getState().sendPhoneCode({ phone })
        : useOnboardingStore.getState().resendEmailCode({ email });
    const success = await action;

    if (success) {
      enqueueSnackbar(t('notify.resendCode'), {
        variant: 'success',
        hideIconVariant: true,
      });
    }
  };

  return (
    <Wrapper>
      <form className="flex flex-col" onSubmit={onSubmit}>
        <h2 className="mb-6">
          {t('pages.verify.title', {
            phoneOrEmail: type === 'email' ? email : phone,
          })}
        </h2>
        <OtpCodeInput name="code" control={control} />
        <button type="submit" className="mb-4" disabled={isButtonDisabled}>
          {isSubmitting ? <CircularIndicator /> : t('common.continue')}
        </button>
        <p className="text-center text-[12px]">
          <Trans
            i18nKey="pages.verify.helperText"
            components={{
              button: (
                <b
                  className="cursor-pointer text-primary"
                  onClick={resendCode}
                />
              ),
            }}
          />
        </p>
      </form>
    </Wrapper>
  );
};

export default Verify;

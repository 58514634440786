import PhoneIcon from '@heroicons/react/24/solid/PhoneIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import React from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useQueryString } from 'common/hooks';
import { useAuthStore } from 'common/stores';
import { getEmailByPhone } from 'common/utils';
import { Wrapper } from 'core/components/common';
import { TextInput } from 'core/components/form';
import CircularIndicator from 'core/components/uikit/CircularIndicator';
import { PATHS, PhoneQueryParams } from 'core/router';

const phoneRegExp = /^\+[1-9]{1,3}\d{3}\d{3}\d{3,6}$/;

const schema = yup
  .object({
    phone: yup
      .string()
      .required(
        t('errors.validation.isRequired', { name: t('pages.phone.field') })
      )
      .matches(
        phoneRegExp,
        t('errors.validation.isInvalid', { name: t('pages.phone.field') })
      ),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const Phone = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    params: { phone },
  } = useQueryString<PhoneQueryParams>();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: { phone: phone ?? '' },
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = methods;

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(async ({ phone }) => {
    const success = await useAuthStore.getState().sendPhoneCode({ phone });

    if (success) {
      navigate(PATHS.getPhoneVerify({ phone }));
    }
  });

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <form className="flex flex-col" onSubmit={onSubmit}>
          <h5 className="mb-3">{t('pages.phone.welcomeText')}</h5>
          <h2 className="mb-6">{t('pages.phone.title')}</h2>
          <TextInput
            name="phone"
            control={control}
            componentProps={{
              type: 'tel',
              className: 'mb-4',
              placeholder: '+15551234567',
              renderIcon: () => <PhoneIcon className="size-5 text-appRose" />,
            }}
          />
          <button type="submit" className="mb-4" disabled={isButtonDisabled}>
            {isSubmitting ? <CircularIndicator /> : t('common.continue')}
          </button>
          <FutureEmailWidget />
        </form>
      </FormProvider>
    </Wrapper>
  );
};

const FutureEmailWidget = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const phoneValue = useWatch({
    control,
    name: 'phone',
  });

  return (
    <p className="text-center text-[12px]">
      {t('pages.phone.helperText', {
        email: getEmailByPhone(phoneValue),
      })}
    </p>
  );
};

export default Phone;

import React from 'react';
import { Outlet } from 'react-router-dom';

import { useScrollToTop } from 'common/hooks';

import Footer from './Footer';

const Layout = () => {
  useScrollToTop();

  return (
    <React.Fragment>
      <header className="py-6" />
      <main className="flex-grow">
        <Outlet />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;

import React from 'react';
import { useController, FieldValues } from 'react-hook-form';

import { DefaultFormInputProps } from './types';
import { TextInput, TextInputProps } from '../uikit';

const TextInputController = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<FormValues, TextInputProps>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;

  return <TextInput {...field} error={error} {...componentProps} />;
};

export default TextInputController;

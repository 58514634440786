import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
  Account,
  AddEmailBody,
  LinkAnotherAccountBody,
  ResendEmailCodeBody,
  VerifyEmailCodeBody,
} from 'common/models';
import { accountRepository } from 'common/repositories';

type Actions = {
  addNewEmailAndSendCode: (body: AddEmailBody) => Promise<boolean>;
  linkAnotherAccount: (body: LinkAnotherAccountBody) => Promise<Account | null>;
  resendEmailCode: (body: ResendEmailCodeBody) => Promise<boolean>;
  verifyEmail: (body: VerifyEmailCodeBody) => Promise<boolean>;
};

export type OnboardingStore = Actions;

const useOnboardingStore = create<OnboardingStore>()(
  immer(() => ({
    addNewEmailAndSendCode: async (body) => {
      const res = await accountRepository.addNewEmailAndSendCode(body);

      return !res.error;
    },

    resendEmailCode: async (body) => {
      const res = await accountRepository.resendEmailCode(body);

      return !res.error;
    },

    verifyEmail: async (body) => {
      const res = await accountRepository.verifyEmail(body);

      return !res.error;
    },

    linkAnotherAccount: async (body) => {
      const res = await accountRepository.linkAnotherAccount(body);

      return res.data;
    },
  }))
);

export default useOnboardingStore;

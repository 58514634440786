import { useCallback, useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export type UseFetchDataArgs<ArgsT, DataT> = {
  args?: ArgsT;
  shouldFetchCb?: (dataInState: DataT | null) => boolean;
};

type FetchState<DataT> = {
  data: DataT | null;
  isLoading: boolean;
};

type FetchActions<DataT, ArgsT = undefined> = {
  fetch: (args?: ArgsT) => Promise<DataT>;
};

export type FetchStore<DataT, ArgsT = void> = FetchState<DataT> &
  FetchActions<DataT, ArgsT>;

const createFetchStore = <DataT, ArgsT = void>(
  apiCall: (args?: ArgsT) => Promise<DataT>
) => {
  const useFetchStore = create<FetchStore<DataT, ArgsT>>()(
    immer((set) => ({
      data: null,
      isLoading: false,
      fetch: async (args?: ArgsT) => {
        set(() => ({ isLoading: true }));

        const res = await apiCall(args as ArgsT);

        set(() => ({ isLoading: false }));

        if (res !== null) {
          set(() => ({ data: res }));
        }

        return res;
      },
    }))
  );

  const useFetchData = ({
    shouldFetchCb,
    args,
  }: UseFetchDataArgs<ArgsT, DataT> = {}) => {
    const data = useFetchStore((state) => state.data);
    const isLoading = useFetchStore((state) => state.isLoading);

    const shouldFetch = useCallback(
      (data: DataT | null) => {
        if (shouldFetchCb) {
          return shouldFetchCb(data);
        } else {
          return data === null;
        }
      },
      [shouldFetchCb]
    );

    useEffect(() => {
      if (shouldFetch(data)) {
        useFetchStore.getState().fetch(args);
      }
    }, []);

    return { data, isLoading };
  };

  return { useFetchStore, useFetchData };
};

export default createFetchStore;

import { Field, Label } from '@headlessui/react';
import { clsx } from 'clsx';
import React, { forwardRef } from 'react';

import DefaultInput, { DefaultInputProps } from './DefaultInput';
import { InputProps } from './types';

export type TextInputProps = InputProps &
  DefaultInputProps & {
    type?:
      | 'email'
      | 'hidden'
      | 'number'
      | 'password'
      | 'search'
      | 'tel'
      | 'text'
      | 'url';
  };

// eslint-disable-next-line react/display-name
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, error, className, ...rest }, ref) => {
    return (
      <Field className={clsx('flex w-full flex-col', className)}>
        {label && <Label className="caption mb-2 ml-1">{label}</Label>}
        <DefaultInput ref={ref} {...rest} />
        {error && (
          <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
        )}
      </Field>
    );
  }
);

export default TextInput;

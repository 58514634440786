import CheckIcon from '@heroicons/react/20/solid/CheckIcon';
import DocumentDuplicateIcon from '@heroicons/react/20/solid/DocumentDuplicateIcon';
import { AnimatePresence, motion } from 'framer-motion';
import { enqueueSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type CopyButtonProps = {
  text: string;
};

const CopyButton: FC<CopyButtonProps> = ({ text }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    if (copied) return;

    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      enqueueSnackbar(t('notify.copied'), {
        variant: 'success',
        hideIconVariant: true,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('errors.api.other'), {
        variant: 'error',
        hideIconVariant: true,
      });
    }
  };

  return (
    <div
      onClick={handleCopy}
      className="flex cursor-pointer flex-row items-center justify-between gap-4 rounded-md border-[1px] border-slate-300 bg-slate-200 px-3 py-4"
    >
      <p className="whitespace-normal break-words text-sm">{text}</p>
      <div className="relative size-6">
        <AnimatePresence>
          {copied ? (
            <motion.div
              key="check"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ duration: 0.3 }}
              className="absolute inset-0 flex items-center justify-center"
            >
              <CheckIcon className="size-6 text-primary" />
            </motion.div>
          ) : (
            <motion.div
              key="duplicate"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ duration: 0.3 }}
              className="absolute inset-0 flex items-center justify-center"
            >
              <DocumentDuplicateIcon className="size-6 text-primary" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default CopyButton;

import {
  Account,
  AddEmailBody,
  LinkAnotherAccountBody,
  ResendEmailCodeBody,
  VerifyEmailCodeBody,
} from 'common/models';
import http from 'core/services/api';

class AccountRepository {
  getAccounts = async () =>
    http.fetch((axios, signal) =>
      axios.get<Account[]>('/accounts/my', { signal })
    );

  addNewEmailAndSendCode = async (body: AddEmailBody) =>
    http.fetch((axios, signal) =>
      axios.patch<object>('/accounts/add-email', body, { signal })
    );

  linkAnotherAccount = async (body: LinkAnotherAccountBody) =>
    http.fetch((axios, signal) =>
      axios.post<Account>('/accounts/add-account', body, { signal })
    );

  resendEmailCode = async (body: ResendEmailCodeBody) =>
    http.fetch((axios, signal) =>
      axios.post<object>('/accounts/email/send-code', body, { signal })
    );

  verifyEmail = async (body: VerifyEmailCodeBody) =>
    http.fetch((axios, signal) =>
      axios.post<object>('/accounts/email/verify', body, { signal })
    );
}

export const accountRepository = new AccountRepository();

import ArrowUpIcon from '@heroicons/react/24/solid/ArrowUpIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as FacebookLogo } from 'assets/svgs/facebook.svg';
import { ReactComponent as InstagramLogo } from 'assets/svgs/instagram.svg';
import { ReactComponent as LinkedinLogo } from 'assets/svgs/linkedin.svg';
import { ReactComponent as TwitterLogo } from 'assets/svgs/twitter.svg';
import { useAuthStore } from 'common/stores';
import PATHS from 'core/router/paths';

import Wrapper from '../Wrapper';

const Footer = () => {
  const { t } = useTranslation();
  const isAuth = useAuthStore((state) => !!state.accessToken);

  return (
    <Wrapper
      as="footer"
      className="bg-slate-100 !pb-16 !pt-32"
      internalClassName="!min-h-full"
    >
      <div className="flex flex-col items-center gap-6 md:flex-row md:items-start md:justify-between md:gap-8">
        {isAuth && (
          <div className="flex flex-col items-center gap-3 md:items-start md:gap-8">
            <p className="caption font-light">
              {t('components.footer.menu.title')}
            </p>
            <div className="flex flex-row flex-wrap justify-center gap-2 md:justify-start">
              <Link to={PATHS.home} className="heading-3 font-light">
                {t('components.footer.menu.home')}
              </Link>
              <p className="heading-2 font-thin">/</p>
              <p
                onClick={useAuthStore.getState().logout}
                className="heading-3 cursor-pointer font-light"
              >
                {t('common.logout')}
              </p>
            </div>
          </div>
        )}

        <div className="flex flex-col items-center gap-3 text-center md:items-start md:gap-8 md:text-left">
          <p className="caption font-light">
            {t('components.footer.contactUs.title')}
          </p>
          <div>
            <p className="caption">1-800-123-4567</p>
            <p className="caption">support@mailnum.com</p>
          </div>
        </div>

        <div className="flex flex-col items-center gap-3 md:items-start md:gap-8">
          <p className="caption font-light">
            {t('components.footer.followUs.title')}
          </p>
          <div className="flex flex-row flex-wrap justify-center gap-2 md:justify-start">
            <a href="#">
              <InstagramLogo />
            </a>
            <a href="#">
              <FacebookLogo />
            </a>
            <a href="#">
              <TwitterLogo />
            </a>
            <a href="#">
              <LinkedinLogo />
            </a>
          </div>
        </div>
      </div>

      <div className="mt-16 flex flex-col items-center justify-between gap-8 md:mt-40 md:flex-row">
        <div className="flex flex-col items-center gap-2 md:flex-row md:gap-6">
          <a href="#" className="caption">
            {t('components.footer.privacy')}
          </a>
          <a href="#" className="caption">
            {t('components.footer.terms')}
          </a>
        </div>
        <a href="#">
          <ArrowUpIcon className="size-4 text-gray-800" />
        </a>
      </div>
    </Wrapper>
  );
};

export default Footer;

import React, { FC, PropsWithChildren, JSX, HTMLAttributes } from 'react';

export type WrapperProps = HTMLAttributes<HTMLOrSVGElement> & {
  as?: keyof JSX.IntrinsicElements;
  internalClassName?: string;
};

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({
  as: Tag = 'div',
  children,
  className,
  internalClassName = '',
  ...rest
}) => {
  return (
    <Tag {...rest} className={`p-block ${className ? className : ''}`}>
      <div
        className={`ml-auto mr-auto min-h-screen max-w-screen-md ${internalClassName}`}
      >
        {children}
      </div>
    </Tag>
  );
};

export default Wrapper;

import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { Layout } from 'core/components/common';
import { Error, Home } from 'features';

import PATHS from './paths';
import ProtectedRoute from './ProtectedRoute';
import { onboardingRoute } from './routes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error />,
    children: [
      // homeRoute,
      {
        index: true,
        element: (
          <ProtectedRoute from="notAuthUser">
            <Home />
          </ProtectedRoute>
        ),
      },
      onboardingRoute,
      {
        path: PATHS.error,
        element: <Error />,
      },
      {
        path: '*',
        element: <Error />,
      },
    ],
  },
]);

export default router;

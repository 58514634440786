import { queryString } from 'common/utils';

export type PhoneQueryParams = { phone: string };
export type PhoneAndEmailQueryParams = PhoneQueryParams & {
  email: string;
};
export type ErrorPageQueryParams = {
  status?: number | null;
  message?: string | null;
};

class Paths {
  home = '/';

  onboarding = '/onboarding';

  phoneKey = 'phone';
  phone = `${this.onboarding}/${this.phoneKey}`;

  phoneVerifyKey = 'verify-phone';
  getPhoneVerify = (params: PhoneQueryParams): string =>
    queryString.stringifyUrl({
      url: `${this.onboarding}/${this.phoneVerifyKey}`,
      query: params,
    });

  emailKey = 'email';
  getEmail = (params: PhoneAndEmailQueryParams): string =>
    queryString.stringifyUrl({
      url: `${this.onboarding}/${this.emailKey}`,
      query: params,
    });

  emailVerifyKey = 'verify-email';
  getEmailVerify = (params: PhoneAndEmailQueryParams): string =>
    queryString.stringifyUrl({
      url: `${this.onboarding}/${this.emailVerifyKey}`,
      query: params,
    });

  doneKey = 'done';
  getDone = (params: PhoneAndEmailQueryParams): string =>
    queryString.stringifyUrl({
      url: `${this.onboarding}/${this.doneKey}`,
      query: params,
    });

  error = '/error';
  getError = (params: ErrorPageQueryParams): string =>
    `${this.error}?${queryString.stringify(params)}`;
}

const PATHS = new Paths();

export default PATHS;

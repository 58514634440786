import { motion } from 'framer-motion';
import React from 'react';

import Logo from 'assets/svgs/logo.svg';
import { Wrapper } from 'core/components/common';

const Splash = () => {
  return (
    <Wrapper internalClassName="flex items-center justify-center h-full">
      <motion.div
        initial={{ zoom: 1.6 }}
        animate={{ zoom: 1.2 }}
        transition={{ ease: 'easeOut', duration: 2 }}
      >
        <img src={Logo} alt="Logo" className="size-48" />
      </motion.div>
    </Wrapper>
  );
};

export default Splash;

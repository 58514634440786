import EnvelopeIcon from '@heroicons/react/24/solid/EnvelopeIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useQueryString } from 'common/hooks';
import { getEmailByPhone } from 'common/utils';
import { Wrapper } from 'core/components/common';
import { TextInput } from 'core/components/form';
import CircularIndicator from 'core/components/uikit/CircularIndicator';
import { PATHS, PhoneAndEmailQueryParams } from 'core/router';

import useOnboardingStore from '../onboardingStore';

const schema = yup
  .object({
    email: yup
      .string()
      .email(t('errors.validation.isInvalid', { name: t('pages.email.field') }))
      .required(
        t('errors.validation.isRequired', { name: t('pages.email.field') })
      ),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const Email = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    params: { phone, email },
  } = useQueryString<PhoneAndEmailQueryParams>();
  const futureEmail = getEmailByPhone(phone);

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: { email },
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = methods;

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(async ({ email }) => {
    const success = await useOnboardingStore
      .getState()
      .addNewEmailAndSendCode({ email, phone });

    if (success) {
      navigate(PATHS.getEmailVerify({ email, phone }));
    }
  });

  return (
    <Wrapper>
      <form className="flex flex-col" onSubmit={onSubmit}>
        <h2 className="mb-6">{t('pages.email.title')}</h2>
        <TextInput
          name="email"
          control={control}
          componentProps={{
            type: 'email',
            className: 'mb-4',
            placeholder: 'name@example.com',
            renderIcon: () => <EnvelopeIcon className="size-5 text-appRose" />,
          }}
        />
        <button type="submit" className="mb-4" disabled={isButtonDisabled}>
          {isSubmitting ? <CircularIndicator /> : t('common.continue')}
        </button>
        <p className="text-center text-[12px]">
          {t('pages.email.helperText', {
            email: futureEmail,
          })}
        </p>
      </form>
    </Wrapper>
  );
};

export default Email;

const locale = {
  translation: {
    common: {
      na: 'N/A',
      cancel: 'Cancel',
      done: 'Done',
      login: 'Login',
      logout: 'Logout',
      signup: 'Sign Up',
      continue: 'Continue',
    },
    noData: {
      accounts: 'No accounts',
    },
    components: {
      footer: {
        menu: {
          title: 'MENU',
          home: 'Home',
        },
        contactUs: {
          title: 'CONTACT US',
        },
        followUs: {
          title: 'FOLLOW US',
        },
        privacy: 'Privacy Policy',
        terms: 'Terms of Service',
      },
    },
    pages: {
      home: {
        name: 'Home',
        welcomeText: 'Welcome Back 👋',
        title: 'Your Mail Number Accounts',
        action: '+ Add New',
      },
      phone: {
        field: 'Phone',
        welcomeText: 'Hi 👋',
        title: 'Let’s start by adding your mobile number',
        helperText:
          'Your mobile number will be your new email address, e.g., {{email}}',
      },
      verify: {
        field: 'Code',
        title: 'We sent a confirmation code to {{phoneOrEmail}}',
        helperText:
          'If you didn’t receive the code, <button>click here</button> to resend it again.',
      },
      email: {
        field: 'Email',
        title: 'Now, let’s add your email address.',
        helperText:
          'Your mobile number will be your new email address, e.g., {{email}}',
      },
      done: {
        title: 'You are all set! 🎉',
        subtitle: 'All you need now is to share your new mailnum email:',
        helperText: 'And any email will be forwarded to {{email}}',
      },
    },
    notify: {
      resendCode: 'Confirmation code was sent successfully',
      copied: 'Copied!',
    },
    errors: {
      api: {
        notAuthorized: 'Not authorized',
        other: 'Something went wrong',
        notFound: 'Not found',
      },
      validation: {
        isRequired: '{{name}} is required',
        isInvalid: '{{name}} is not valid',
      },
    },
  },
};

export default locale;

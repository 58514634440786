import React from 'react';
import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useRouteError, Link } from 'react-router-dom';

import { useQueryString } from 'common/hooks';
import { Wrapper } from 'core/components/common';
import { ErrorPageQueryParams } from 'core/router';

const Error = () => {
  const { t } = useTranslation();
  const error = useRouteError();
  const { params } = useQueryString<ErrorPageQueryParams>();
  const isRouteError = isRouteErrorResponse(error);
  const defaultError = t('errors.api.other');

  let { status, message } = params;

  if (isRouteError) {
    status = error.status;
    message = error.statusText || error.data.message;
  }

  return (
    <Wrapper className="*:flex *:flex-col *:items-center *:justify-center">
      <h3>Oops! {status && status}</h3>
      <h5>{message || defaultError}</h5>
      <Link to="/">{t('pages.home.name')}</Link>
    </Wrapper>
  );
};

export default Error;

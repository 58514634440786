import ArrowRightIcon from '@heroicons/react/20/solid/ArrowRightIcon';
import { clsx } from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getEmailByPhone } from 'common/utils';
import { Wrapper } from 'core/components/common';
import { CircularIndicator } from 'core/components/uikit';
import { PATHS } from 'core/router';

import { useFetchAccounts } from './accountsStore';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper internalClassName="flex flex-col">
      <p className="responsive-paragraph mb-3 text-appRose">
        {t('pages.home.welcomeText')}
      </p>
      <h2 className="mb-6">{t('pages.home.title')}</h2>
      <AccountsList />
      <button onClick={() => navigate(PATHS.phone)}>
        {t('pages.home.action')}
      </button>
    </Wrapper>
  );
};

const AccountsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useFetchAccounts({
    shouldFetchCb: () => true,
  });

  if (isLoading) {
    return (
      <div className="mb-6 flex w-full items-center justify-center">
        <CircularIndicator />
      </div>
    );
  }

  if (!data) {
    return <p className="mb-6 text-center">{t('noData.accounts')}</p>;
  }

  return (
    <div className="mb-6 flex flex-col gap-3">
      {data?.map(({ phone, email, emailVerified }, index) => (
        <div
          key={index}
          className={clsx(
            'flex flex-row items-center justify-between gap-4 rounded-md bg-white px-3 py-4',
            { 'cursor-pointer': !emailVerified }
          )}
          onClick={() => {
            if (emailVerified) return;

            navigate(
              PATHS.getEmail({
                phone: phone ?? '',
                email: email ?? '',
              })
            );
          }}
        >
          <div>
            <p className="text-sm">{getEmailByPhone(phone)}</p>
            {!emailVerified && (
              <p className="text-sm text-appRose">
                Please complete the verification.
              </p>
            )}
          </div>

          {!emailVerified && <ArrowRightIcon className="size-5" />}
        </div>
      ))}
    </div>
  );
};

export default Home;

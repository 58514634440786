import React from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { Phone, Verify, Email, Done } from 'features/Onboarding';

import PATHS from '../paths';
import ProtectedRoute from '../ProtectedRoute';

const onboardingRoute: RouteObject = {
  path: PATHS.onboarding,
  children: [
    {
      index: true,
      loader: () => {
        return redirect(PATHS.phone);
      },
    },
    {
      path: PATHS.phoneKey,
      element: <Phone />,
    },
    {
      path: PATHS.phoneVerifyKey,
      element: <Verify type="phone" />,
    },
    {
      path: PATHS.emailKey,
      element: (
        <ProtectedRoute from="notAuthUser">
          <Email />
        </ProtectedRoute>
      ),
    },
    {
      path: PATHS.emailVerifyKey,
      element: (
        <ProtectedRoute from="notAuthUser">
          <Verify type="email" />,
        </ProtectedRoute>
      ),
    },
    {
      path: PATHS.doneKey,
      element: (
        <ProtectedRoute from="notAuthUser">
          <Done />
        </ProtectedRoute>
      ),
    },
  ],
};

export default onboardingRoute;

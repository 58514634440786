import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import {
  Tokens,
  SendPhoneCodeBody,
  VerifyPhoneCodeBody,
  Account,
} from 'common/models';
import { authRepository } from 'common/repositories';
import http from 'core/services/api';
import storage from 'core/services/storage';

type State = Tokens;

type Actions = {
  logout: () => void;
  refresh: () => Promise<void>;
  setTokenToHeadersFromStore: () => void;
  sendPhoneCode: (body: SendPhoneCodeBody) => Promise<boolean>;
  verifyPhoneCode: (body: VerifyPhoneCodeBody) => Promise<Account | null>;
};

export type AuthStore = State & Actions;

const defaultState: State = {
  accessToken: null,
  refreshToken: null,
};

const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      ...defaultState,

      setTokenToHeadersFromStore: () => {
        const { accessToken } = get();

        if (accessToken) {
          http.setAuthTokenToHeaders(accessToken);
        }
      },

      sendPhoneCode: async (body) => {
        const res = await authRepository.sendPhoneCode(body);
        const data = res.data;

        return !!data;
      },

      verifyPhoneCode: async (body) => {
        const res = await authRepository.verifyPhoneCode(body);
        const data = res.data;

        if (data === null) {
          return null;
        }

        const { accessToken, refreshToken } = data;

        if (accessToken !== null) {
          set(() => ({
            accessToken,
            refreshToken,
          }));
          http.setAuthTokenToHeaders(accessToken);
        }

        return data.data;
      },

      refresh: async () => {
        const { refreshToken, logout } = get();

        if (!refreshToken) {
          return logout();
        }

        http.setAuthTokenToHeaders(refreshToken);
        const res = await authRepository.refreshTokens();
        const data = res.data;

        if (data === null) {
          return;
        }

        const { accessToken, refreshToken: newRefreshToken } = data;

        if (accessToken !== null) {
          set(() => ({
            accessToken,
            refreshToken: newRefreshToken,
          }));

          http.setAuthTokenToHeaders(accessToken ?? '');
        } else {
          logout();
        }
      },

      logout: () => {
        set(() => defaultState);
        http.clearAuthTokenFromHeaders();
      },
    }),
    {
      name: storage.AUTH_STORE_KEY,
    }
  )
);

export default useAuthStore;

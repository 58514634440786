import React, { Fragment, FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthStore } from 'common/stores';

import PATHS from './paths';

type ProtectedRoute = {
  from: 'authUser' | 'notAuthUser';
};

const ProtectedRoute: FC<PropsWithChildren<ProtectedRoute>> = ({
  children,
  from,
}) => {
  const isAuth = useAuthStore((state) => state.accessToken !== null);
  const souldRedirect =
    (from === 'authUser' && isAuth) || (from === 'notAuthUser' && !isAuth);

  if (souldRedirect) {
    let redirectPath;

    switch (from) {
      case 'authUser':
        redirectPath = PATHS.home;
        break;
      case 'notAuthUser':
        redirectPath = PATHS.phone;
        break;
    }

    return <Navigate to={redirectPath} />;
  }

  return <Fragment>{children}</Fragment>;
};

export default ProtectedRoute;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useQueryString } from 'common/hooks';
import { getEmailByPhone } from 'common/utils';
import { Wrapper } from 'core/components/common';
import { CopyButton } from 'core/components/uikit';
import { PATHS, PhoneAndEmailQueryParams } from 'core/router';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    params: { phone, email },
  } = useQueryString<PhoneAndEmailQueryParams>();
  const futureEmail = getEmailByPhone(phone);

  return (
    <Wrapper internalClassName="flex flex-col gap-8">
      <h2>{t('pages.done.title')}</h2>
      <p>{t('pages.done.subtitle')}</p>
      <CopyButton text={futureEmail} />
      <p>{t('pages.done.helperText', { email })}</p>
      <button onClick={() => navigate(PATHS.home)}>{t('common.done')}</button>
    </Wrapper>
  );
};

export default Home;
